* {
    box-sizing: border-box;
}
#root {
    max-width: 1440px;
    margin: 0 auto;
}
body {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
}

code {
    font-family: 'Neue Montreal';
}

ol,
ul,
li {
    list-style: none;
}

a {
    text-decoration: none;
}
