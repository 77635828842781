article {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.3s linear;
}
section {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.3s linear;
}
article.activeSlide,
section.activeSlide {
    opacity: 1;
    transform: translateX(0);
}
article.lastSlide,
section.lastSlide {
    transform: translateX(-100%);
}
article.nextSlide,
section.nextSlide {
    transform: translateX(100%);
}
